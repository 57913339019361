<template>
  <div class="container">
    <div class="h2">自助退款申请</div>

    <div class="formBox">
      <div class="input">
        <van-field v-model="json.trade_no" placeholder="请输入微信订单号例: DJH5-123456" input-align="center" :border="false" ref="trade_no"/>
      </div>

      <div class="mt50"><van-button round type="info" size="large" block @click="onSubmit">立即退款</van-button></div>
    </div>
    <div class="note" v-html="html"></div>
  </div>

</template>
<script>
  import {PostDzRefund} from '../services/services'
  export default {
    name:'videoSuccess',
    components: {},
    data () {
      return {
        json:{
          trade_no:''
        },
        html:''
      }
    },
    created(){

    },
    computed:{
    },
    mounted(){
      this.findHtml()
    },
    methods:{
      findHtml(){
        this.html='<h4>注意事项：</h4><p>1.本页面为自助退款页面，无需人工审核。</p><p>提交退费申请后，相关费用将第一时间原路退回到您的支付账户中。</p><p>2.若输入的交易单号查询不到您的订单。</p><p>请联系在线客服或拨打4006178158咨询人工客服联系退费。</p><p>3.退款成功后，您购买的短剧服务将立即失效。</p><p>感谢您的理解。</p><h4>如何查看微信支付交易单号</h4><p><img src="https://image.youzuanmy.vip/image/zdd/tuik.png" width="100%"/></p>'
      },
      onSubmit(){
        if(this.json.trade_no == ''){
          this.$toast('请输入支付宝或微信支付交易单号')
          this.$refs.trade_no.focus()
          return false
        }
        PostDzRefund(this.json).then(result => {
          if(result.data.code==0){
            this.$toast.success(result.data.msg)
            this.json.trade_no = ''
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{padding:20px 18px 20px;background:#fff;
    .h2{font-size:24px;padding:30px 0px;text-align:center;}
    .formBox{padding:0px 4px;
      .input{width:90%;margin:auto;}
      .van-cell{background:none;border:1px solid #f1f1f1;border-radius:10px;}

    }
    /deep/.note{margin-top:20px;
      h4{font-size:18px;padding:10px 0px;margin:0px;}
      p{line-height:24px;}
    }
  }
</style>
